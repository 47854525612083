.footer {
    color: #181818;
    opacity: 0.6;
    font-size: 11px;
    font-weight: bold;
    height: 44px;

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 140%;

    border-top: 1px solid rgba(24,24,24,0.3);
}
.copyright {
    margin-top: 10px !important;
}