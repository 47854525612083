.card-1,
.card-2,
.card-3 {
  margin-left: 10px;
  margin-right: 10px;
}

@media (min-width: 768px) {
  .card-1 {
    margin-left: 20px;
    margin-right: 0;
  }
  .card-2 {
    margin-left: 10px;
    margin-right: 10px;
  }
  .card-3 {
    margin-left: 0;
    margin-right: 20px;
  }
}

@media (min-width: 992px) {
  .card-2 {
    margin-left: 30px;
    margin-right: 30px;
  }
}

.h-card {
  max-width: 414px;
  margin-bottom: 20px;

  background: #ffffff;
  box-shadow: 0 16px 30px rgba(24, 24, 24, 0.1);
  border-radius: 8px;
  padding: 40px;
}
.h-card:hover {
  filter: drop-shadow(0 9px 44px rgba(51, 76, 207, 0.13));
}
.h-card:hover::before {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  height: 3px;
  border-radius: 2px;
  background: rgba(51, 76, 207, 0.13);
}

.h-card-img {
  width: auto;
  height: auto;
}

.h-card-title {
  min-height: 55px;
  font-size: 22px;
  font-weight: 600;
  margin-top: 21px;
  margin-bottom: 4px;
}

.h-card-description {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;  
  text-align: center;
  margin-bottom: 23px;
}

.cards-container {
  max-width: 414px;
}

@media (min-width: 768px) {
  .h-card-img {
    width: 55px;
    height: 55px;
  }
  .h-card-title {
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 4px;
  }
  .cards-container {
    max-width: 1300px;
  }
}
@media (min-width: 992px) {
  .h-card-img {
    width: auto;
    height: auto;
  }
  .h-card-title {
    font-size: 22px;
    margin-top: 24px;
    margin-bottom: 4px;
  }
}

.h-card-button {
  width: 197px;
  height: 44px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  background: linear-gradient(
    68.71deg,
    #8a2387 -71.8%,
    #e94057 33.71%,
    #f27121 126.8%
  );

  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 100%;

  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
}
@media (min-width: 768px) {
  .h-card-button {
    width: 128px;
    height: 39px;
  }
  .h-card {
    padding: 20px;
  }
}
@media (min-width: 992px) {
  .h-card-button {
    width: 197px;
    height: 44px;
  }
  .h-card {
    padding: 40px;
  }
}
.h-card-button:focus {
  outline: 0;
  border: none;
}
.h-card-button:active {
  border: none;
  box-shadow: 0 5px 5px 0 rgba(51, 76, 207, 0.3);
}

.h-card-features {
  margin-top: 40px;
  text-align: center;
  margin-bottom: 16px;
  font-size: 16px;
  font-family: "Open sans";
  font-weight: 600;
}
@media (min-width: 768px) {
  .h-card-features {
    font-weight: bold;
    margin-top: 16px;
    margin-bottom: 12px;
    font-size: 16px;
  }
}
@media (min-width: 992px) {
  .h-card-features {
    font-weight: bold;
    margin-top: 29px;
    font-size: 14px;
  }
}
.h-card ul {
  margin-left: 5%;
  padding-left: 35;
  text-align: left;
  font-size: 14px;
  color: #05003b;
}
.h-card li {
  list-style: none;
  background: url("../../img/check.svg") no-repeat 0 6px;
  padding-left: 15px;
  margin-bottom: 8px;
}
