.form-col {
  max-width: 400px;
}
.form-holder {
  position: relative;
}
.form-holder {
  max-width: 400px;
  min-width: 260px;
  background-color: #fff;
  padding: 28px;
  margin-bottom: 40px;
  box-shadow: 0 16px 28px rgba(24, 24, 24, 0.08);
  border-radius: 8px;
}
.form-title {
  margin-bottom: 27px;
  font-size: 28px;
  font-weight: bold;
  color: #181818;
}
.form-checkbox {
    content: "";
    width: 12px;
    height: 12px;
    margin: 0 4px 0 0;
    border: solid 1px #a8a8a8;
    line-height: 14px;
    text-align: center;
    border-radius: 100%;

    background: #f6f6f6;
    background: radial-gradient(#f6f6f6, #dfdfdf);
}
@media (min-width: 768px) {
  .form-col {
    max-width: 540px;
  }
  .form-holder {
    margin-left: 17px;
    margin-right: 17px;
    max-width: 540px;
    padding-left: 24px;
    padding-right: 24px;
  }
}

@media (min-width: 1024px) {
  .form-col {
    max-width: 700px;
  }
  .form-holder {
    margin-left: 17px;
    margin-right: 17px;
    max-width: 700px;
    padding-left: 24px;
    padding-right: 24px;
  }
}

.form-group {
  margin-bottom: 22px;
}
.form-holder label::after {
  content: '*';
  padding-left: 2px;
  color: #c10f2e;
}
.last-form-group {
  margin-bottom: 40px;
}

.phone-number {
  margin-bottom: 59px;
}
#CountryCode {
  width: 30%;
  display: inline-block;
  float: left;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
#PhoneNumber {
  width: 70%;
  display: inline-block;
  float: left;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.field-error {
  color: #c10f2e;
  text-align: right;
}
.form-label {
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 0;
}
.form-control {
  font-size: 12px;
  font-weight: 400;
  border: 1px solid #c5c5c5;
  border-radius: 4px;
}
.form-control:focus {
  box-shadow: none;

  border: double 1px transparent;
  background-image: linear-gradient(white, white),
    linear-gradient(68.71deg, #8a2387 -71.8%, #e94057 33.71%, #f27121 126.8%);
  background-origin: border-box;
  background-clip: padding-box, border-box;
}
.form-select {
  border: 1px solid #c5c5c5;
  border-radius: 4px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 8px 8px 12px;

  color: #464646;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;

  background: url("../../img/select-arrow.svg") no-repeat;
  background-position: calc(100% - 0.75rem) center !important;
  appearance: none !important;
  padding-right: 2rem !important;
}
.form-select:focus {
  box-shadow: none;
  border: double 1px #e94057;
}
.btn-primary {
  width: 140px;
  height: 44px;
  background: linear-gradient(68.71deg, #8a2387 -71.8%, #e94057 33.71%, #f27121 126.8%);
  border: 0;
  border-radius: 4px;
  font-size: 14px;
  font-weight: bold;
  color: white;
  text-transform: uppercase;
}
.btn-primary:active {
  background: linear-gradient(-68.71deg, #8a2387 -71.8%, #e94057 33.71%, #f27121 126.8%);
}
.btn-link {
  color: #464646;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  text-decoration: none;
  padding: 8px 20px;
}
#agreement {
  height: 400px;
}
.form-loader {
  position: absolute;
  left: 0 ;
  top: 0;
  right: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255,255,255, 0.6);
}
.spinner-border {
  color: #e94057
}
