.header {
    background-color: #F2F2F2 !important;
    backdrop-filter: blur(6px);
    border-bottom: 2px solid #E7E7EE;
    color: #0d1b31;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    width: 100%;

}
.navbar {
    background-color: #F6FAFF !important;
    min-height: 67px;
    margin-left: 0;
    margin-right: 0;
    padding-left: 5%;
    padding-right: 5%;
    
    width: 100%;
    justify-content: space-between;
}
.navbar-name {
    text-transform: uppercase;
}
.nav-link {
    color: #0d1b31 !important;
}
.nav-link:active {
    color: #525964 !important;
}

.login-btn {
    background-color: white;
    width: 98px;
    height: 44px;
    padding-bottom: 3px;
    border-radius: 2px;
    font-size: 15px;
    font-weight: 600;
    border: none;
}
.login-btn:focus {
    outline: 0;
}
.login-btn:active {
    box-shadow: 0 4px 4px 0 rgba(51, 76, 207, 0.13);
    border: none;
}

.navbar-toggler {
    border: none;
}
.navbar-toggler:focus {
    outline: 0;
}